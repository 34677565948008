<template>
    <div class="you-want-waiting">
        <div class="you-want-waiting__container" @click.stop>
            <div class="you-want-waiting__body">
                <h2 class="you-want-waiting__title">{{ $t("modal_waiting.title") }}</h2>
                <div class="you-want-waiting__close" @click="$emit('close')">
                    <img :src="require(`@/assets/img/` + close)" :alt="close" />
                </div>
                <div class="you-want-waiting__last-time" :class="sec > 15 ? 'last-time' : ''">
                    {{ $t("modal_waiting.last") }} : {{ countSec + 1 - sec }}
                </div>
                <div class="you-want-waiting__buttons">
                    <button class="you-want-waiting__no btn btn-bgn" @click="$router.push('/')">
                        {{ $t("modal_waiting.no") }}
                    </button>
                    <button class="you-want-waiting__yes btn btn-green" @click="$emit('close')">
                        {{ $t("modal_waiting.yes") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "t-you-want-waiting",
    props: {
        sec: {
            type: Number,
            require: false,
        },
        countSec: {
            type: Number,
            require: false,
        },
    },
    data() {
        return {
            close: "close.png",
        };
    },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
/* You want waiting */
.last-time {
    visibility: visible !important;
    opacity: 1 !important;
    transition: opacity 0.2s ease;
}
.you-want-waiting {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background: rgba(0, 0, 0, 0.25);
    height: 100%;
    margin: auto;
    &__container {
        width: 100%;
        max-width: 560px;
        background: #fff;
        border-radius: 30px;
        box-shadow: 1px 3px 25px rgba(0, 45, 29, 0.1);
        overflow: hidden;
        position: relative;
    }

    &__body {
        position: relative;
        padding: 100px 20px 20px;
    }

    &__title {
        font-weight: 700;
        line-height: 54.56px;
        text-align: center;
        color: #000;
        font-size: 40px;
        font-family: "Nunito Sans";
        white-space: pre-wrap;
        @media (max-width: $ms3) {
            font-size: 20px;
            line-height: 30px;
            padding-top: 50px;
        }
    }

    &__last-time {
        text-align: center;
        color: red;
        font-size: 20px;
        font-family: "Nunito Sans";
        margin-top: 20px;
        visibility: hidden;
        opacity: 0;
        @media (max-width: $ms3) {
            font-size: 14px;
        }
    }

    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 48px;
        height: 48px;
        cursor: pointer;
        &:hover,
        &:focus {
            opacity: 0.5;
            transition: 0.2s ease 0.2s;
        }
        @media (max-width: $ms3) {
            width: 30px;
            height: 30px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        margin: 80px -8px 0;
        .btn {
            width: 100%;
            height: 80px;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            line-height: 30.01px;
            text-align: center;
            color: #fff;
            font-size: 22px;
            margin: 0 8px;
            font-family: "Nunito Sans";
            @media (max-width: $ms3) {
                height: 50px;
                line-height: 20px;
                font-size: 14px;
                border-radius: 10px;
            }
        }
    }
}
</style>
