<template>
    <div class="payment">
        <div class="payment__container _container">
            <div class="payment__body">
                <h1 class="payment__title" v-if="onProccess">
                    {{ $t("thanks") }}<br />
                    <span>{{ $t("done_order") }}</span>
                </h1>
                <div class="onProccess">
                    <div class="loads">
                        <img src="@/assets/img/svg/flower.svg" alt="loads" />
                    </div>
                </div>
                <div class="order_number" v-if="!onProccess">
                    <!-- <h1 class="payment__title">Заказ отправлен!</h1> -->
                    <span class="order-number">{{ $t("payment.number_order") }}</span
                    ><br /><br />
                    <p v-if="iikoOrderInfo.guest_number" class="guest_number">
                        {{ iikoOrderInfo.guest_number }}
                    </p>
                    <br />
                    <p v-if="!iikoOrderInfo.guest_number" class="guest_number">
                        {{ iikoOrderInfo.alter_name }}
                    </p>
                    <br />
                    <div class="order-notifications">
                        <p>{{ $t("text_pay_order1") }}</p>
                        <p>{{ $t("text_pay_order2") }}</p>
                    </div>
                    <button class="btn-again-order" @click="$router.push('/')">{{ $t("btn_again_order") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { api } from "@/api/api";

export default {
    name: "t-payment",
    data() {
        return {
            onProccess: true,
            paymentError: true,
            orderObj: null,
            iikoOrderInfo: { guest_number: null },
            attempt: 1,
            orderId: localStorage.getItem("orderId"),
            interval: "",
        };
    },

    methods: {
        getIikoOrder() {
            if (!this.iikoOrderInfo.guest_number && this.attempt <= 4) {
                axios
                    .get(api + "check-iiko-order/?order_id=" + this.orderId)
                    .then((response) => {
                        this.iikoOrderInfo = response.data;

                        if (this.iikoOrderInfo.guest_number) {
                            this.onProccess = false;
                            this.attempt = 4;

                            this.getLocalOrder();
                        } else {
                            this.attempt++;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },

        getLocalOrder() {
            console.log("Присовение локального номера заказа");
            this.onProccess = false;

            axios
                .get(api + `orders/` + this.orderId + "/")
                .then((response) => {
                    this.orderObj = response.data;
                    if (this.orderObj) {
                        axios
                            .get("http://127.0.0.1:5000/receipt/print/?text=" + this.orderObj.receipt_text)
                            .then((response) => {
                                console.log(response.data);
                            })
                            .catch((error) => {
                                console.log("Ошибка при печати чека: " + error);
                            });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },

    mounted() {
        if (!this.orderId) {
            this.$router.push("/");
        }
        this.interval = setInterval(() => {
            this.getIikoOrder();
        }, 3000);
    },

    watch: {
        attempt() {
            console.log("Попытка получить номер заказа: " + this.attempt);
            if (this.attempt === 4) {
                clearInterval(this.interval);
                if (!this.iikoOrderInfo.guest_number) {
                    this.getLocalOrder();
                }
            }
        },

        onProccess() {
            clearInterval(this.interval);
            if (!this.onProccess) {
                setTimeout(() => this.$router.push("/"), 10000);
            }
        },
    },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
@import "~@/assets/scss/payment.scss";
.guest_number {
    font-size: 200px;
    line-height: 100px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    padding-bottom: 2rem;
    @media (max-width: $ms3) {
        font-size: 100px;
    }
}
.payment {
    &__container {
        width: 100%;
        @media (max-width: $ms3) {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.loads {
    width: 187px;
    height: 164px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 200px auto 150px;
    position: relative;
    @media (max-width: $ms3) {
        margin: 150px auto;
    }
    &::before {
        content: "";
        position: absolute;
        border-top: 1px solid #fff;
        border-left: 3px solid #fff;
        border-radius: 50%;
        transform: rotate(0);
        animation: rotateMove 1s infinite 1s;
        @keyframes rotateMove {
            0% {
                transform: rotate(0);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.order_number,
.onProccess {
    margin-top: 50px;
}

.onProccess {
    .loads {
        width: 187px;
        height: 164px;

        &::before {
            width: 352px;
            height: 352px;
        }
    }
}

.order_number {
    .loads {
        width: 139px;
        height: 122px;

        &::before {
            width: 260.56px;
            height: 260.56px;
        }
    }
}

.order-notifications {
    font-weight: 700;
    font-size: 22px;
    line-height: 30.01px;
}

.btn-again-order {
    width: 480px;
    height: 80px;
    box-shadow: 1px 3px 25px rgba(0, 45, 29, 0.1);
    border-radius: 20px;
    font-weight: 700;
    font-size: 30px;
    line-height: 40.92px;
    color: #28735f;
    background: #fff;
    margin-top: 60px;
    font-family: "Nunito Sans";
    @media (max-width: $ms3) {
        width: 100%;
        height: 50px;
        font-size: 20px;
        line-height: 30px;
        border-radius: 10px;
    }
    &:hover,
    &:focus {
        background: #eee;
        transition: 0.2s ease 0.2s;
    }
}
.download-app {
    width: 895px;
    height: 517px;
    margin: 200px auto 0;
    border-radius: 35px;
    overflow: hidden;
    @media (max-width: $ms3) {
        width: 100% !important;
        height: 350px !important;
        margin: 0 auto !important;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.give-check {
    position: absolute;
    bottom: 0;
    right: 1rem;
    @include adaptive-font(55, 14, 0);
    @include adaptive-value("line-height", 50, 20, 0);
}

.order-number {
    font-size: 36px;
    line-height: 49.1px;
}

.order-number font {
    font-weight: 700;
    font-size: 200px;
    line-height: 272.8px;
}

.order-info {
    @include adaptive-font(55, 14, 0);
    @include adaptive-value("line-height", 50, 20, 0);
    width: 50%;
    display: block;
    margin: 1rem auto 0;
}
</style>
