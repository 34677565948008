<template>
    <div class="full-display">
        <div class="_container">
            <div class="full-display__container">
                <div class="full-display__body">
                    <h1 class="full-display__title">{{ title }}</h1>
                    <div class="full-display__input-password">
                        <label for="password">{{ label }}</label>
                        <input
                            type="text"
                            name="password"
                            id="password"
                            v-model="password"
                            placeholder="Введите пароль"
                        />
                    </div>
                    <div class="full-display__keyboard">
                        <button
                            class="full-display__key btn btn-beige"
                            v-for="i in 9"
                            :key="i"
                            @click="password += String(i)"
                        >
                            {{ i }}
                        </button>
                        <button class="full-display__key btn btn-beige" @click="password = ''">Очистить</button>
                        <button class="full-display__key btn btn-beige" @click="password += '0'">0</button>
                        <button
                            class="full-display__key btn btn-beige"
                            @click="password = password.substring(0, password.length - 1)"
                        >
                            Удалить
                        </button>
                    </div>
                    <router-link to="/" class="full-display__btn-back btn btn-beige">Назад</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "t-full-display",
    data() {
        return {
            title: "Можно выйти из полноэкранного режима",
            label: "Введите пароль",
            password: "",
        };
    },
    methods: {
        handleKeyDown(event) {
            // Проверяем, была ли нажата клавиша F11
            if (event.key === "F11") {
                event.preventDefault();
            }
        },
    },
    mounted() {
        window.addEventListener("keydown", this.handleKeyDown);
    },
    beforeUnmount() {
        window.removeEventListener("keydown", this.handleKeyDown);
    },
    watch: {
        password(value) {
            if (value === "14362") {
                if (document.fullscreenElement !== null) {
                    document
                        .exitFullscreen()
                        .then(() => {
                            console.log("Вы вышли из полноэкранного режима!");
                            this.$router.push("/welcome");
                        })
                        .catch((err) => {
                            console.error("Ошибка выхода из полноэкранного режима:", err);
                        });
                } else {
                    console.log("Вы не находитесь в полноэкранном режиме.");
                    this.$router.push("/welcome");
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
.full-display {
    background-color: $green;
    height: 100vh;
    display: flex;
    align-items: center;

    &__body {
        color: #fff;
    }

    &__title {
        font-weight: 700;
        @include adaptive-font(100, 30, 0);
        @include adaptive-value("line-height", 120, 50, 0);
        text-align: center;
    }

    &__input-password {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
        @include adaptive-font(50, 18, 0);
        @include adaptive-value("line-height", 51, 20, 0);
        input {
            font-size: 20px;
            padding: 0 10px;
        }
    }

    &__btn-back {
        width: 50%;
        height: 50px;
        display: flex;
        justify-content: center;
        margin: 100px auto 0;
    }

    &__keyboard {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 50%;
        margin: 20px auto 0;
    }

    &__key {
        width: 25%;
        height: 50px;
        display: flex;
        justify-content: center;
        margin: 5px;
    }

    #password {
        width: 50%;
        height: 35px;
        margin-top: 5px;
    }
}
</style>
