<template>
    <div class="back-to-page" @click="$router.push('/products')">
        <div class="_container">
            <div class="back-to-page__container flex-center">
                <t-btn-arrow />
                <h1 class="back-to-page__title">{{ $t("cart.title") }}</h1>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "t-back-to-page",
    data() {
        return {
            img: "arrow-back.svg",
        };
    },
};
</script>

<style lang="scss">
.back-to-page {
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    &__container {
        width: 100%;
        height: 112px;
        position: relative;
    }
    &__title {
        font-weight: 700;
        font-size: 28px;
        line-height: 38.19px;
        color: #000;
        @media (max-width: 767px) {
            font-size: 20px;
            line-height: 30px;
        }
    }
    .btn.btn-arrow {
        position: absolute;
        left: 0;
        cursor: pointer;
    }
}
</style>
