import { createRouter, createWebHistory } from "vue-router";
import Main from "./pages/Main";
import Welcome from "./pages/Welcome";
import Products from "./pages/Products";
import OrderDetailed from "./pages/OrderDetailed";
import Payment from "./pages/Payment";
import Pay from "./pages/Pay";
import NotWorking from "./pages/NotWorking";
import СatalogSets from "./pages/СatalogSets";
import Finish from "./pages/Finish";
import FullDisplay from "./pages/FullDisplay.vue";

export default createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            component: Main,
        },
        {
            path: "/full-display",
            component: FullDisplay,
        },
        {
            path: "/welcome",
            component: Welcome,
        },
        {
            path: "/products",
            component: Products,
        },
        {
            path: "/cart/",
            component: OrderDetailed,
        },
        {
            path: "/payment",
            component: Payment,
        },
        {
            path: "/pay",
            component: Pay,
        },
        {
            path: "/not-working/",
            component: NotWorking,
        },
        {
            path: "/fast-sets",
            component: СatalogSets,
        },
        {
            path: "/finish",
            component: Finish,
        },
    ],
});
